import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Header from "./components/Header";
import toast from "react-hot-toast";
import {
  HiOutlineLogin,
  HiOutlineUserCircle
} from "react-icons/hi";
import LoadingScreen from "./components/LoadingScreen";
import SideBar from "./components/SideBar";
import { useDispatch } from "react-redux";
import { removeUser } from "./slices/dataSlice";
import ProgressBar from "@badrap/bar-of-progress";
import NotificationModal from "./components/models/NotificationModal";

// Adding the progressBar
const progress = new ProgressBar({
  // The size (height) of the progress bar.
  // Numeric values get converted to px.
  size: 2,

  // Color of the progress bar.
  // Also used for the glow around the bar. #5cfdad
  color: "#004C2B",

  // Class name used for the progress bar element.
  className: "bar-of-progress",

  // How many milliseconds to wait before the progress bar
  // animation starts after calling .start().
  delay: 100,
});

// progress.start();

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const Home = Loadable(lazy(() => import("./pages/Home")));
const MessageTiles = Loadable(lazy(() => import("./pages/MessageTiles")));
const Chart = Loadable(lazy(() => import("./pages/Chart")));
const Profile = Loadable(lazy(() => import("./pages/Profile")));
const Dashboard = Loadable(lazy(() => import("./pages/Dashboard")));
const Enquiries = Loadable(lazy(() => import("./pages/Enquiries")));
const Reports = Loadable(lazy(() => import("./pages/Reports")));
const Events = Loadable(lazy(() => import("./pages/Events")));
const Quiz = Loadable(lazy(() => import("./pages/Quiz")));
const Results = Loadable(lazy(() => import("./pages/Result")));
const CompleteCourses = Loadable(lazy(() => import("./pages/CompletedCourses")));
const ExpandedCases = Loadable(lazy(() => import("./pages/ExpandCases")));
const Analytics = Loadable(lazy(() => import("./pages/Analytics")));
const ProgramCourses = Loadable(lazy(() => import("./pages/ProgramCourses")));
const InfiniteChatGround = Loadable(lazy(() => import("./pages/InfiniteChatGround")));
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));


function App() {

  const [sidebar, setSideBar] = useState(true);
  const [logoutNotification, setLogoutNotification] = useState(false);
  const [logout, setLogout] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handlePress = () => {
    setSideBar(!sidebar);
  };


  const showDropDown = (e) => {
    e.preventDefault();
    setLogout(!logout);
  };

  const handleLogoutNoticeVisibility = () => {
    setLogout(false);
    setLogoutNotification(!logoutNotification);
  }

  const handleRoute = (e) => {
    setLogout(false);
    navigate('/profile');
  }

  // Handling the logout..
  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    dispatch(removeUser());
    toast.success("Successfully logged out.");
    navigate("/");
  };

  // Handling the horizontal progress bar at the top on each route..
  useEffect(() => {
    const unlisten = () => {
      progress.start();

      setTimeout(() => {
        progress.finish();
      }, 2000);
    };

    unlisten();

    return () => {
      progress.finish();
    };
  }, [location]);

  return (
    <div className="h-screen overflow-y-hidden bg-radiography-gray relative">
      
      <Toaster position="top-right" />

      { logoutNotification && <NotificationModal title={"Logout"} prompt={"Are you sure you want to logout ?"} setColse={handleLogoutNoticeVisibility} onNotify={handleLogout} />}

      <Header
        press={handlePress}
        sidebar={sidebar}
        showDropDown={showDropDown}
      />

      { logout && (
        <div className="absolute top-12 md:top-16  right-5 md:right-8 z-50 bg-white shadow-xl text-gray-700 rounded-lg h-auto w-auto p-3 lg:py-3 lg:px-5">
          <div className="py-1">
            <div className="right-3 md:right-4 absolute w-4 h-4  mt-1 bg-white -top-3  rotate-45"></div>
          </div>

          <div
            onClick={handleRoute}
            className="text-sm hover:bg-gray-200 hover:rounded-md py-1 px-3 flex items-center space-x-3 cursor-pointer border-b mb-2 text no-underline "
          >
            <span className="">
              <HiOutlineUserCircle className="w-5 h-5 hover:scale-105 transition ease-out duration-300 " />
            </span>
            <span>Profile</span>
          </div>

          <p
            onClick={handleLogoutNoticeVisibility}
            className="text-sm hover:bg-gray-200 hover:rounded-md py-1 px-3 cursor-pointer flex items-center space-x-3"
          >
            <span className="">
              <HiOutlineLogin className="w-5 h-5 hover:scale-105 transition ease-out duration-300 " />
            </span>
            <span>Logout</span>
          </p>
        </div>
      )}

      {/* overflow-scroll scrollbar-hide */}
      <main className="relative flex h-full">
        {/* Modifying SideBar for both mobile and desktop */}
        <div className="hidden lg:inline-flex  h-screen">
          {sidebar && <SideBar />}
        </div>

        {/* SideBar for the mobile view */}
        <div className="absolute top-0 w-12 left-0 z-50 lg:hidden h-screen">
          {!sidebar && <SideBar />}
        </div>

        {/* children for components */}

        <div className="w-full h-full bg-gray-900 pt-1 md:pt-3 pl-1 md:pl-3">
          <>
            <Routes>
              <Route path="/programs" element={<Home />} />
              <Route path="/program/:programId" element={<ProgramCourses />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/messages" element={<Chart />} />
              <Route path="/charts" element={<MessageTiles />} />
              <Route path="/enquiries" element={<Enquiries />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/events" element={<Events />} />
              <Route path="/quiz" element={<Quiz />} />
              <Route path="/results" element={<Results />} />
              <Route path="/completed" element={<CompleteCourses />} />
              <Route path="/detailedReport" element={<ExpandedCases />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/infinitechatground" element={<InfiniteChatGround />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </>
        </div>
      </main>
    </div>
  );
}

export default App;
