import React, { useState } from "react";
import { ChatAlt2Icon } from "@heroicons/react/solid";
import { ChevronRightIcon } from "@heroicons/react/outline";
import {
  HiOutlineQuestionMarkCircle,
  HiOutlineCloudUpload,
} from "react-icons/hi";
import { TbUsersGroup } from "react-icons/tb";
import { MdOutlineDashboard } from "react-icons/md";
import { VscSymbolEvent } from "react-icons/vsc";
import { FaUncharted } from "react-icons/fa";
import { FaGraduationCap } from "react-icons/fa";
import SidebarRow from "./SidebarRow";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/dataSlice";

function SideBar() {
  const currentUser = useSelector(selectUser);
  const [open, setOpen] = useState(true);

  return (
    <div
      className={` ${
        open ? "w-44 md:w-56 lg:w-64 xl:w-72 2xl:w-[440px]" : "w-16"
      } p-2 relative bg-gray-700 text-gray-100 rounded-sm h-full duration-300
       opacity-80 lg:opacity-100  lg:overflow-y-auto pb-20 md:pb-32 scrollbar-hide hover:scrollbar`}
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
        className={`absolute cursor-pointer rounded-full  h-7 w-7 top-9 -right-3 z-50 bg-gray-400 flex 
      items-center justify-center ${open && "rotate-180"} lg:hidden`}
      >
        <ChevronRightIcon className=" h-4 text-white" />
      </div>

      {currentUser?.isAdmin && (
        <SidebarRow
          Icon={FaUncharted}
          title="Analytics"
          open={open}
          path="/analytics"
        />
      )}

      {currentUser?.isAdmin && (
        <SidebarRow
          Icon={MdOutlineDashboard}
          title="Dashboard"
          open={open}
          path="/dashboard"
        />
      )}

      <SidebarRow
        Icon={FaGraduationCap}
        title="Programs"
        open={open}
        path="/programs"
      />

      <SidebarRow
        Icon={ChatAlt2Icon}
        title="Private Chats"
        open={open}
        path="/charts"
      />

      <SidebarRow
        Icon={TbUsersGroup}
        title="Infinite Chat"
        open={open}
        path="/infinitechatground"
      />

      <SidebarRow
        Icon={VscSymbolEvent}
        title="Events"
        open={open}
        path="/events"
      />

      <SidebarRow
        Icon={HiOutlineCloudUpload}
        title="Case Studies"
        open={open}
        path="/reports"
      />

      {!currentUser?.isAdmin && (
        <SidebarRow
          Icon={HiOutlineQuestionMarkCircle}
          title="Enquiries"
          open={open}
          path="/enquiries"
        />
      )}
    </div>
  );
}

export default SideBar;
