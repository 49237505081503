import React from 'react';

function LoadingScreen() {
  return (
    <div className='flex items-center justify-center bg-radiography-gray h-screen'>
      <img src="/images/sonographyloader.gif" className='w-32 h-32' alt="Loader" />
    </div>
  );
}

export default LoadingScreen;

