import React, { useState } from "react";
import { MenuIcon, SearchIcon, XIcon } from "@heroicons/react/outline";
import { HiOutlineArrowsExpand } from "react-icons/hi";
import { VscEye } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { searchItems, selectUser } from "../slices/dataSlice";

function Header({ press, sidebar, showDropDown }) {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [showsearch, setShowSearch] = useState(true);

  // console.log("USER INFORMATION", user);

  useEffect(() => {
    dispatch(searchItems({ search }));
    // console.log(search);
  }, [search, dispatch]);

  return (
    <header>
      <div className="relative top-0  flex  space-x-3 justify-between bg-gray-900  flex-grow py-2 px-3 ">
        {/* Left */}
        <Link
          to="/"
          className="mt-1  flex flex-col md:flex-row items-center cursor-pointer"
        >
          <div className="flex items-center space-x-2">
            <img
              src="/images/Infinite-foundation.png"
              alt="logo"
              className="h-8 md:h-14"
            />
            <p className="hidden text-white md:inline-flex">Infinite-Foundation</p>
            <p className="text-white md:hidden">IF</p>
          </div>
        </Link>

        {/* middle  */}

        <div className="w-8 h-8 md:h-10 md:w-10 lg:w-16 lg:h-16">
          <img
            src="/images/probe.png"
            alt="logo"
            className="w-full h-full object-cover"
          />
        </div>

        {/* <div className=" hidden md:inline-flex items-center  md:w-[400px] lg:w-[600px]  my-3 h-10 rounded-md bg-orange-500   hover:bg-orange-700  cursor-pointer">
          <input
            placeholder="Search....."
            className="p-2 h-full w-full flex-grow flex-shrink rounded-l-md rounded-r-md focus:outline-none px-4"
            type="text"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />

          <SearchIcon className="h-12 p-4 text-white" />
        </div> */}

        {/* <div
          onClick={(e) => {
            e.preventDefault();
            setShowSearch(false);
          }}
          className="md:hidden flex items-center justify-center bg-gray-900 w-10 h-10 rounded-full"
        >
          <SearchIcon className="h-5 w-5  text-gray-100" />
        </div> */}

        {/* Right */}
        <div className="flex space-x-4 items-center justify-around text-gray-100 ">
          <div className="flex flex-col items-center">
            <p className="hidden md:inline-flex cursor-pointer">
               Boosting the Eye of Health {" "}
            </p>
            <p className="hidden md:inline-flex cursor-pointer">
              through Sonography{" "}
            </p>
          </div>

          <VscEye className="hidden md:inline-flex h-8 w-8 animate-pulse transition-all duration-300  cursor-pointer" />

          <div className="link flex flex-col">
            <p className=" text-xs md:text-sm text-center "> Hello ,👏 </p>
            <p className=" text-xs md:text-sm text-center">
              {" "}
              {user.name ? user?.name.split(" ")[0] : ""}{" "}
            </p>
          </div>

          <div
            onClick={showDropDown}
            className="w-10 h-10 md:w-14 md:h-14  rounded-full cursor-pointer"
          >
            <img
              src={
                user?.imageUrl ||
                `https://ui-avatars.com/api/name=${user?.name.split(" ")[0]}&background=random`
              }
              className="w-full h-full rounded-full border-2  hover:scale-105 object-cover"
              alt="user-profile"
            />
          </div>
        </div>
      </div>

      {/* Search Bar on mobile */}

      {/* <div
        className={`flex items-center bg-gray-800  flex-grow p-4 md:hidden ${
          showsearch && "hidden"
        }`}
      >
        <div
          className={`flex items-center h-10  rounded-md bg-orange-500   hover:bg-orange-600 flex-grow cursor-pointer ${
            showsearch && "hidden"
          }`}
        >
          <input
            placeholder="Search....."
            className="p-2 h-full w-6 flex-grow flex-shrink rounded-l-md rounded-r-md focus:outline-none px-4"
            type="text"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            onClick={(e) => {
              e.preventDefault();
              setShowSearch(true);
            }}
            className="h-6 w-6 text-gray-200"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div> */}

      {/* bottom nav */}

      <div
        className={`flex items-center p-2 pl-4 space-x-3 bg-gray-800 border-none text-white text-sm `}
      >
        <p onClick={press} className="link flex items-center lg:hidden">
          {sidebar ? (
            <MenuIcon className="h-6 mr-1" />
          ) : (
            <XIcon className="h-6 mr-1" />
          )}
        </p>

        <marquee width="100%" direction="left" height="20px">
          <p className="text-bold h-5 ">
            Infinite-Foundation SONO COMMUNITY in partnership with x-ray
            care and Society of Radiography in Uganda.
          </p>
        </marquee>
      </div>
    </header>
  );
}

export default Header;
