import React from "react";
import { Link, useLocation } from "react-router-dom";

function SidebarRow({ Icon, title, open, path }) {

  const location = useLocation();

  return (
    <Link to={ path } >
      <div
        className={`flex items-center space-x-2 p-2 hover:bg-gray-200 
    rounded-lg cursor-pointer duration-500 active:border-b-2 active:border-[#009856]
     hover:text-black my-2 md:my-5 2xl:my-10 ${ location.pathname === path ? "bg-gray-200 text-black" : "" }
    `}
      >
        { Icon && <Icon className="icon text-[#009856]" />}

        <p className={` ${!open && "hidden"} flex-1 2xl:ml-10 font-medium duration-500`}>
          { title }
        </p>
      </div>
    </Link>
  );
}

export default SidebarRow;
