import { combineReducers, configureStore } from '@reduxjs/toolkit';
import counterReducer from '../slices/counterSlice';
import dataReducer from '../slices/dataSlice';
import examReducer from '../slices/examSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


const rootReducer = combineReducers({
  counter: counterReducer,
  data: dataReducer,
  exam: examReducer,
});

const persistConfig = {
   key : 'root',
   storage,
   version: 1
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck : false
  }),
});

export const persistor = persistStore(store)